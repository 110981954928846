body {
  position: fixed;
  width: 100vw;
  height: 100vh;
}
#app {
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
}