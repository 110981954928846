.root {
  flex-grow: 1;
}
.flex {
  flex-grow: 1;
}
.menuButton {
  margin-left: -12;
  margin-right: 20;
}
